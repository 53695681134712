import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { format, parseISO, startOfWeek, endOfWeek } from "date-fns";
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Papa from "papaparse";

// Centralized constants
const API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;
const DRAWER_WIDTH = 240; // Standard sidebar width

const VIEW_TYPES = {
  DAY: "day",
  WEEK: "week",
};

const ROLE_TYPES = {
  ALL: "all",
  STUDENT: "0",
  TEACHER: "1",
};

const UserSignUpList = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  // State Management with More Descriptive Names
  const [userData, setUserData] = useState({
    users: [],
    isLoading: false,
    error: null,
  });
  const [filters, setFilters] = useState({
    date: format(new Date(), "yyyy-MM-dd"),
    viewType: VIEW_TYPES.DAY,
    roleFilter: ROLE_TYPES.ALL,
  });

  // Memoized Data Filtering
  const filteredUsers = useMemo(() => {
    return userData.users.filter(
      (user) =>
        filters.roleFilter === ROLE_TYPES.ALL ||
        user.role === Number(filters.roleFilter)
    );
  }, [userData.users, filters.roleFilter]);

  // Fetch User Data Effect
  useEffect(() => {
    const fetchUserData = async () => {
      setUserData((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        const params =
          filters.viewType === VIEW_TYPES.WEEK
            ? {
                date: filters.date,
                type: 1,
                startDate: format(
                  startOfWeek(parseISO(filters.date)),
                  "yyyy-MM-dd"
                ),
                endDate: format(
                  endOfWeek(parseISO(filters.date)),
                  "yyyy-MM-dd"
                ),
              }
            : { date: filters.date };

        const response = await axios.get(
          `${API_BASE_URL}/admin/users/by-date`,
          { params }
        );

        setUserData((prev) => ({
          ...prev,
          users: response.data,
          isLoading: false,
        }));
      } catch (error) {
        setUserData((prev) => ({
          ...prev,
          error:
            error.response?.data?.message || "An unexpected error occurred",
          isLoading: false,
        }));
      }
    };

    fetchUserData();
  }, [filters]);

  // Utility Functions
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const downloadCSV = () => {
    const headers = [
      "No",
      "Username",
      "Slug",
      "Standard",
      "Name",
      "Mobile",
      "Role",
      "Created Date",
    ];

    const csvData = filteredUsers.map((user, index) => ({
      No: index + 1,
      Username: user.username,
      Slug: user.slug,
      Standard: user.standard || "NA",
      Name: user.name,
      Mobile: user.whatsapp,
      Role: user.role === 0 ? "Student" : "Teacher",
      "Created Date": user.created_at,
    }));

    const csv = Papa.unparse({ fields: headers, data: csvData });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", `user-signups_${filters.date}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Render Functions
  const renderUserTable = () => {
    if (userData.isLoading) {
      return (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2">Loading user data...</Typography>
          </Grid>
        </Grid>
      );
    }

    if (userData.error) {
      return (
        <Alert severity="error">
          {userData.error || "No sign-ups found for the selected date"}
        </Alert>
      );
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                "No",
                "Username",
                "Slug",
                "Standard",
                "Name",
                "Mobile",
                "Role",
                "Created Date",
              ].map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user, index) => (
              <TableRow key={user.user_id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.slug}</TableCell>
                <TableCell>{user.standard || "NA"}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.whatsapp}</TableCell>
                <TableCell>
                  {user.role === 0 ? "Student" : "Teacher"}
                </TableCell>
                <TableCell>{user.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Header onDrawerToggle={handleDrawerToggle} />
      <Sidebar 
        mobileOpen={mobileOpen} 
        onDrawerToggle={handleDrawerToggle}
      />
      
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          width: { 
            xs: '100%', 
            md: `calc(100% - ${DRAWER_WIDTH}px)` 
          },
          ml: { 
            md: `${DRAWER_WIDTH}px` 
          },
          mt: { 
            xs: 8,  // Adjust based on mobile header height
            md: 10  // Adjust based on desktop header height
          }
        }}
      >
        <Container maxWidth="lg">
          <Card>
            <CardHeader
              title="User Sign-Up List"
              subheader="View and manage user registrations"
            />
            <CardContent>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Select Date"
                    type="date"
                    fullWidth
                    value={filters.date}
                    onChange={(e) => handleFilterChange("date", e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      filters.viewType === VIEW_TYPES.DAY ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleFilterChange("viewType", VIEW_TYPES.DAY)}
                  >
                    Day-wise
                  </Button>
                  <Button
                    variant={
                      filters.viewType === VIEW_TYPES.WEEK ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleFilterChange("viewType", VIEW_TYPES.WEEK)}
                    sx={{ ml: 2 }}
                  >
                    Week-wise
                  </Button>
                </Grid>
                <Grid item>
                  <Select
                    value={filters.roleFilter}
                    onChange={(e) => handleFilterChange("roleFilter", e.target.value)}
                    label="Role Filter"
                  >
                    <MenuItem value={ROLE_TYPES.ALL}>All Roles</MenuItem>
                    <MenuItem value={ROLE_TYPES.STUDENT}>Students</MenuItem>
                    <MenuItem value={ROLE_TYPES.TEACHER}>Teachers</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={downloadCSV}>
                    Download CSV
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {renderUserTable()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

export default UserSignUpList;