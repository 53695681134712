import React from "react";
import { Link } from "react-router-dom";
import "../assets/js/slide";

const Sidebar = () => {
  return (
    <>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link" activeClassName="active">
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>
          {/* End Dashboard Nav */}
          {/* <li className="nav-item">
            <Link
              to="/nmms-admin"
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
            >
              <i className="bi bi-book"></i>
              <span>NMMS</span>
              <i className="bi bi-chevron-down ms-auto" />
            </Link>
            <ul
              id="components-nav"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/nmms-admin" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>NMMS Main</span>
                </Link>
              </li>

              <li>
                <Link to="/nmms-admin" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>NMMS Topic</span>
                </Link>
              </li>
            </ul>
          </li> */}

          {/* Gyan Sadhana */}
          {/* <li className="nav-item">
            <Link to="/gyansadhana-admin" className="nav-link collapsed">
              <i className="bi bi-card-text"></i>
              <span>Gyan Sadhana</span>
            </Link>
          </li> */}

          {/* Navoday */}
          {/* <li className="nav-item">
            <Link to="/navoday-admin" className="nav-link collapsed">
              <i className="bi bi-journal"></i>
              <span>Navoday</span>
            </Link>
          </li> */}

          {/* End Components Nav */}
          {/* <li className="nav-item">
            <Link to="/dtm-admin" className="nav-link collapsed">
              <i className="bi bi-person-lines-fill"></i>
              <span>Digital Teacher Mission</span>
            </Link>
          </li> */}
          {/* End Forms Nav */}
          {/* End Icons Nav */}
          {/* <li className="nav-heading">Web App</li> */}
          {/* <li className="nav-item">
            <Link to="/test" className="nav-link">
              <i className="bi bi-person" />
              <span>Profile</span>
            </Link>
          </li> */}
          {/* End F.A.Q Page Nav */}
          {/* <li className="nav-item">
            <Link to="/blog-main" className="nav-link">
              <i className="bi bi-file-earmark-text" />             <span>Blog</span>
            </Link>
          </li> */}

          <li className="nav-item">
            <Link to="/admin/user-data" className="nav-link">
              <i className="bi bi-person"></i>
              <span>User Data</span> &nbsp;
              <span className="text-danger"> New!</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/add-school" className="nav-link">
              <i className="bi bi-book"></i>
              <span>Add School</span> &nbsp;
              <span className="text-danger"></span>
            </Link>
          </li>

          {/* Chapters */}
          <li className="nav-item">
            <Link to="/upload-chapters" className="nav-link">
              <i className="bi bi-journal"></i>
              <span>Upload Chapters</span> &nbsp;
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/upload-topic" className="nav-link">
              <i className="bi bi-file-earmark-text" />{" "}
              <span>Upload Topics</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/upload-edumitra" className="nav-link">
              <i className="bi bi-person-lines-fill"></i>
              <span>Upload EduMitra</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/upload-edumitra-content" className="nav-link">
              <i className="bi bi-card-text"></i>
              <span>EduMitra Content</span>
            </Link>
          </li>
        </ul>
      </aside>
      {/* End Sidebar*/}
    </>
  );
};

export default Sidebar;
