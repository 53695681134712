import React from 'react';
import { Link } from 'react-router-dom';

const Page_error = () => {
  return (
    <main>
      <div className="container">
        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center text-center">
          <div style={{ maxWidth: '600px', padding: '20px', textAlign: 'center' }}>
            <h1 style={{ fontSize: '6rem', fontWeight: 'bold', color: '#ff6b6b' }}>404</h1>
            <p style={{ fontSize: '1.5rem', color: '#555' }}>
              Oops! The page you're looking for doesn't exist.
            </p>
            <p style={{ marginBottom: '20px', color: '#888' }}>
              It seems like the link you followed is broken or the page has been moved.
            </p>
            <b style={{ fontSize: '1.25rem', color: '#007bff' }}>Edutor App</b>
            <br />
            <Link
              className="btn btn-primary"
              to="/"
              style={{
                marginTop: '20px',
                padding: '10px 20px',
                fontSize: '1rem',
                textDecoration: 'none',
                color: '#fff',
                backgroundColor: '#007bff',
                borderRadius: '5px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              Back to Home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Page_error;
